import React from 'react'
import './heroBanner.styles.scss'
import logo from '../../assets/iconos/lo-3bh_Dolby.svg'

const Loading = () => {
  return (
    <div className="loading">
      <img className="loader" src={logo} alt="logo3BH" />
      <p>LOADING</p>
      <div className="lds-ellipsis">
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>
    </div>
  )
}

export default Loading
