import React, { useEffect } from 'react'
import './home.styles.scss'
import FixedMenu from '../../fixedMenu/fixedMenu.component'
import HeroBanner from '../../heroBanner/heroBanner.component'
// IMPORT MODULES
import {
  BrowserView,
  MobileView,
  isBrowser,
  isMobile
} from 'react-device-detect'

import Footer from '../../footer/footer.component'
import HomeInstagram from '../../HomeInstagram/homeInstagrams.component'
import ProyectosAll from '../../proyectos/ProyectosAll.component'
import ProyectosMobile from '../../ProyectosMobile/ProyectosMobile.component'
import Partners from '../../Partners/Partners.component'
//  components/Partners/Partners.component.jsx
import DescansoUno from './descansoUno.component'
import DescansoDos from './descansoDos.component'
import logo from '../../../assets/iconos/LO_3bh-SMALL.svg'

import LoadingMask from 'react-loadingmask'
import 'react-loadingmask/dist/react-loadingmask.css'

const HomePage = () => {
  useEffect(() => {
    window.scrollTo(0, 0)
    // console.log('gototop')
  }, [])

  return (
    <div className="homepage">
      {/* <TheCursor/> */}
      <div className="menu">
        <FixedMenu path="/" />
      </div>

      {/* <LoadingMask loading={true} text={"loading..."}>
            <div style={{ width: '100%', height: '100%' }}> <HeroBanner /> </div>
        </LoadingMask> */}

      <HeroBanner />
      <DescansoUno />

      <BrowserView>
        <ProyectosAll plus="" numbervid="5" />
      </BrowserView>

      <MobileView>
        <ProyectosMobile numbervid="5" />
      </MobileView>

      {/* <Video category='18' video='second' muter='mSecond' btn='btnSecond'/> */}

      <HomeInstagram />
      <DescansoDos />
      <Partners />
      {/* <HomeFooter />  */}
      {/* <AnimatedBackground /> */}
      {/*
       */}

      <Footer />
    </div>
  )
}

export default HomePage
