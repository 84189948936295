import React, { Component } from 'react'

import './homeInstagram.styles.scss'

import TituloFlecha from '../globals/TituloFlecha/TituloFlecha.component'

import TimeInsta from './insta.component'

const TOKEN = process.env.REACT_APP_INSTAGRAM_ACCESS_TOKEN

const IURL = `https://graph.instagram.com/me/media?fields=id,username,media_url&access_token=${TOKEN}`

class HomeInstagram extends Component {
  state = {
    error: null,
    isLoaded: false,
    items: [],
    gA: 1
  }

  componentDidMount() {
    fetch(IURL)
      .then(res => res.json())
      .then(
        result => {
          this.setState({
            isLoaded: true,
            items: result.data
          })
        },
        // Nota: es importante manejar errores aquí y no en
        // un bloque catch() para que no interceptemos errores
        // de errores reales en los componentes.
        error => {
          this.setState({
            isLoaded: true,
            error
          })
        }
      )
  }

  render() {
    const { error, isLoaded, items } = this.state

    const RenderOrNotInstagram = () => {
      if (items) {
        return (
          <>
            <TituloFlecha txt="@3bhmex" />
            <div className="sectionInstagram">
              <div className="timeInstagram">
                {items.map(item => {
                  if (item.media_url.indexOf('video') === -1) {
                    return (
                      <TimeInsta
                        num={this.state.gA++}
                        key={item.id}
                        id={item.id}
                        media_url={item.media_url}
                      />
                    )
                  } else {
                    return null
                  }
                })}
              </div>
            </div>
          </>
        )
      } else {
        return <div className="emptyInstagram"></div>
      }
    }

    if (error) {
      return <div>Error: {error.message}</div>
    } else if (!isLoaded) {
      return <div className="carga">Cargando 3BH...</div>
    } else {
      return (
        <div className="spaceInstagram">
          <RenderOrNotInstagram />
        </div>
      )
    }
  }
}

export default HomeInstagram
