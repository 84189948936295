import React from 'react'
import './fixedMenu.styles.scss'
// import logo from '../../assets/iconos/LO_3bh-SMALL.svg'
import logo from '../../assets/iconos/lo-3bh_Dolby.svg'

import { Route, Link, BrowserRouter, withRouter } from 'react-router-dom'

const Logo = props => {
  return (
    <a href="/">
      <div className="logo3bh">
        <img src={logo} alt="logo3BH" />
      </div>
    </a>
  )
}

export default Logo
