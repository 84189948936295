import React from 'react';
import './Proyectos.styles.scss';

// IMPORT MODULES

function ProyectosPicture(props) {
    const pic = React.createRef();

    return(

        <div ref={pic} className="proPic">
        
            <img src={props.img} alt={props.alt} />

        </div>
        )

}

export default ProyectosPicture;