import React, { useState, useEffect, useCallback, useRef } from 'react'
// import ReactDOM from 'react-dom';
import audifonos from '../../assets/iconos/audifonos.svg'
import './heroBanner.styles.scss'

import Loading from './Loading'

import ReactPlayer from 'react-player'

import {
  BrowserView,
  MobileView,
  isBrowser,
  isMobile
} from 'react-device-detect'
// IMPORTS

function stringToHTML(str) {
  var parser = new DOMParser()
  var doc = parser.parseFromString(str, 'text/html')
  var divVideo = doc.getElementsByClassName('wp-video')
  var pathElement = divVideo.item(0).innerText
  return pathElement
}

function stringToPoster(imgP) {
  let parser = new DOMParser()
  let doc = parser.parseFromString(imgP, 'text/html')
  let poster = doc.getElementsByClassName('wp-video')
  let pathPoster = poster.item(0).childNodes[2].attributes[4].nodeValue
  return pathPoster
}

function Video(props) {
  const [isLoading, setIsLoading] = useState(true)
  const [videoState, setVideoState] = useState(null)
  let [muteState, setMuteState] = useState(true)

  const video = useRef(null)
  const muter = useRef(null)
  const btn = useRef(null)

  async function ctrVideo(vid) {
    var i = 0
    var sounds = document.querySelectorAll('video')

    for (i = 0; i < sounds.length; i++) {
      sounds[i].muted = true
    }

    vid.current.volume = 0.5
    vid.current.muted = !vid.current.muted
    if (vid.current.paused) {
      vid.current.play()
    }
  }

  async function Mute(vcr) {
    vcr.current.muted = !vcr.current.muted
    // vcr.current.muted = false
  }

  async function HideMuter(hm) {
    hm.current.style.display = 'block'
  }

  async function HideBtn(hb) {
    hb.current.style.display = 'none'
  }

  useEffect(() => {
    setIsLoading(true)
    const apiUrl = `https://3bh.mx/api/wp-json/wp/v2/posts?categories=${props.category}&page=1&per_page=1`
    fetch(apiUrl)
      .then(res => res.json())
      .then(items => {
        setVideoState(items)
        setIsLoading(false)
      })
  }, [props.category])

  // useEffect(() => {

  function handleToggleMuted() {
    setMuteState((muteState = !muteState))
  }

  // })

  return (
    <>
      {isLoading ? (
        <Loading />
      ) : (
        <div className="video3bh">
          <BrowserView>
            <div className="btnContenedor">
              <button
                ref={btn}
                id={props.btn}
                onClick={() => {
                  HideMuter(muter)
                  ctrVideo(video)
                  HideBtn(btn)
                }}
                className="audifonos"
              >
                <img src={audifonos} alt="activar" />
                <p>Activar Sonido</p>
              </button>
              <button
                ref={muter}
                id={props.muter}
                onClick={() => {
                  Mute(video)
                  HideMuter(btn)
                  HideBtn(muter)
                }}
                className="muter"
              >
                <img src={audifonos} alt="Mute" />
                <p>Mute Sound</p>
              </button>
              <div href="#" className="goDown">
                scroll {/*<br />| <br />| */}
              </div>
            </div>

            {videoState &&
              videoState.map((vid, index) => (
                <video
                  ref={video}
                  key={index}
                  playsInline
                  autoPlay
                  muted
                  loop
                  controls
                  src={stringToHTML(vid.content.rendered)}
                ></video>
              ))}
          </BrowserView>

          <MobileView>
            <div className="btnContenedor">
              <button
                ref={btn}
                id={props.btn}
                onClick={() => {
                  HideMuter(muter)
                  ctrVideo(video)
                  handleToggleMuted()
                  HideBtn(btn)
                }}
                className="audifonos"
              >
                <img src={audifonos} alt="activar" />
                <p>Activar Sonido</p>
              </button>

              <button
                ref={muter}
                id={props.muter}
                onClick={() => {
                  Mute(video)
                  HideMuter(btn)
                  HideBtn(muter)
                  handleToggleMuted()
                }}
                className="muter"
              >
                <img src={audifonos} alt="Mute" />
                <p>Mute Sound</p>
              </button>
            </div>

            <ReactPlayer
              width="100%"
              height="100vh"
              loop={true}
              className="videoMobile"
              ref={video}
              muted={muteState}
              playsinline={true}
              playing={true}
              url="https://3bh.mx/api/wp-content/uploads/2021/07/videofinal-web1080DiscordNitroSmall.mp4"
            />
          </MobileView>
        </div>
      )}
    </>
  )
}

export default Video
