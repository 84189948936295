import React from 'react'
import './App.css';
import HomePage from './components/pages/home/home.component';

import PaginaProyectoDetail from './components/pages/Proyecto/PaginaProyectoDetail.component'

import { Switch, Route } from 'react-router-dom';

function App() {

  return (
    <div className="App">
      <Switch>
        <Route exact path='/' component={HomePage} />
        <Route exact path={`/proyectos/:itemId`} component={PaginaProyectoDetail} />
      </Switch>  
    </div>
  );
}

export default App;
