import React from 'react';
import ReactDOM from 'react-dom';
import './heroBanner.styles.scss';

import Video from './video.component'

const HeroBanner = () => (
    <div className="heroBanner">
      <Video category='14' video='first' muter='mFirst' btn='btnFirst' cont='contenedorMP' />
    </div>
)

export default HeroBanner;