import React from 'react';

import './insta.styles.scss';


const TimeInstagram = ( { id, media_url, num } ) => {
  
   
    return(
        
        //    instaImage
        <div className={`grid${num}`}><img className={`instaImage grid${num}`} src={media_url} alt={`insta${id}`} /></div>
        
    )
};




export default TimeInstagram;


